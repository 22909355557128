import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
		<path d="M5436 9433 c-2 -3 -40 -7 -83 -9 -98 -5 -289 -29 -385 -49 -101 -21
-389 -94 -413 -106 -11 -5 -60 -23 -110 -39 -49 -17 -114 -42 -143 -56 -29
-13 -55 -24 -57 -24 -3 0 -43 -18 -90 -41 -80 -39 -110 -59 -86 -59 6 0 11 4
11 8 0 4 21 14 46 21 36 11 64 12 133 3 72 -8 87 -8 94 4 5 8 12 12 18 9 12
-8 12 -75 -1 -75 -5 0 -10 5 -10 10 0 6 -9 10 -20 10 -11 0 -20 -5 -20 -11 0
-7 -10 -9 -24 -7 -33 7 -276 -8 -301 -18 -18 -7 -18 -6 -6 9 22 28 -105 -32
-199 -94 -25 -16 -53 -32 -62 -35 -10 -4 -18 -10 -18 -15 0 -5 -5 -9 -11 -9
-6 0 -25 -11 -43 -25 -18 -14 -38 -25 -44 -25 -6 0 -15 -6 -19 -14 -4 -7 -18
-18 -30 -25 -80 -42 -364 -281 -524 -441 -236 -238 -411 -462 -590 -760 -68
-114 -204 -382 -239 -469 -11 -30 -29 -73 -40 -95 -29 -64 -105 -298 -143
-441 -81 -299 -117 -515 -138 -835 -17 -257 2 -660 42 -885 67 -376 132 -602
271 -945 41 -101 197 -413 242 -485 11 -16 29 -46 41 -65 255 -412 588 -779
959 -1056 90 -68 321 -224 330 -224 3 0 30 -15 59 -34 50 -32 266 -144 347
-179 214 -94 424 -167 610 -212 58 -14 121 -29 140 -35 35 -9 192 -34 325 -52
119 -15 607 -21 729 -9 244 26 352 43 516 82 115 28 176 46 345 101 214 70
516 211 737 345 78 47 145 89 148 93 3 3 28 22 55 40 459 313 872 772 1167
1298 129 230 214 424 310 707 45 135 123 443 142 570 4 22 11 65 16 96 27 154
40 357 40 608 0 335 -23 564 -85 866 -78 375 -258 838 -464 1189 -61 104 -232
357 -267 395 -13 14 -55 66 -92 114 -88 115 -371 404 -492 501 -52 42 -101 81
-108 87 -7 5 -17 7 -22 4 -6 -4 -9 -3 -8 2 8 23 -223 190 -244 177 -6 -4 -8
-3 -5 3 11 17 -186 139 -205 127 -6 -4 -8 -2 -5 3 4 6 0 14 -9 17 -8 3 -13 2
-10 -3 4 -5 -3 -7 -15 -4 -13 4 -16 8 -9 13 11 7 -59 60 -80 60 -6 0 -38 14
-71 30 -33 17 -64 28 -70 24 -6 -3 -19 3 -30 15 -26 27 -62 36 -89 21 -18 -10
-24 -9 -31 1 -6 10 -9 11 -9 2 0 -9 -13 -11 -40 -9 -31 3 -40 1 -40 -11 0 -13
-15 -15 -102 -11 -56 3 -208 0 -337 -7 -130 -7 -240 -10 -245 -7 -14 8 -176 9
-176 1 0 -4 33 -12 73 -19 39 -6 86 -16 102 -22 27 -9 70 -19 145 -32 14 -3
45 -12 69 -20 24 -9 52 -16 61 -16 10 0 31 -6 47 -14 15 -8 71 -31 123 -52
117 -45 326 -146 400 -192 192 -120 327 -215 435 -306 39 -32 95 -80 125 -105
30 -25 84 -74 120 -108 36 -34 88 -83 116 -110 176 -166 243 -235 374 -389 40
-47 190 -244 190 -250 0 -3 19 -36 43 -72 24 -37 58 -89 75 -117 33 -53 124
-236 187 -375 37 -81 43 -89 133 -160 196 -157 285 -276 348 -470 8 -25 19
-60 25 -77 6 -18 8 -37 5 -41 -3 -5 -38 -14 -78 -21 -40 -7 -89 -17 -109 -22
-20 -5 -52 -9 -71 -9 l-34 0 6 -50 c7 -58 14 -66 94 -109 96 -52 180 -113 197
-142 5 -10 16 -151 24 -315 9 -165 20 -337 25 -384 6 -47 10 -112 10 -145 0
-55 -3 -63 -38 -103 -21 -23 -42 -42 -48 -42 -6 0 -19 -9 -29 -20 -10 -11 -42
-34 -71 -51 -29 -16 -54 -34 -56 -40 -2 -5 -41 -29 -88 -53 -82 -41 -85 -44
-82 -75 1 -17 -5 -48 -13 -67 -8 -20 -15 -45 -15 -56 0 -31 -65 -222 -82 -242
-4 -6 -8 -18 -8 -28 0 -18 -6 -32 -59 -151 -17 -37 -31 -70 -31 -73 0 -14
-133 -270 -181 -347 -29 -48 -63 -103 -74 -121 -28 -45 -216 -293 -256 -338
-19 -21 -61 -68 -94 -105 -62 -71 -241 -239 -310 -293 -22 -17 -58 -45 -79
-63 -84 -69 -258 -184 -401 -265 -56 -32 -374 -187 -530 -259 -114 -53 -160
-73 -265 -115 -73 -29 -294 -97 -350 -108 -36 -7 -108 -23 -160 -35 -157 -38
-287 -49 -565 -49 -354 0 -565 26 -900 113 -128 33 -427 147 -477 182 -14 11
-31 19 -38 19 -13 0 -132 58 -140 68 -3 4 -25 18 -50 31 -165 90 -444 310
-590 465 -30 33 -80 85 -110 117 -90 93 -116 123 -146 170 -16 24 -31 46 -34
49 -33 30 -210 284 -210 301 0 4 -23 45 -51 91 -29 46 -69 121 -91 168 -22 47
-54 117 -73 155 -18 39 -39 88 -46 110 -7 22 -16 45 -20 50 -15 21 -113 333
-128 405 -5 25 -18 81 -29 125 -24 93 -60 317 -73 457 -20 213 -12 901 12 979
5 19 14 72 20 119 16 144 24 195 32 206 4 7 16 58 28 115 24 116 29 225 15
280 -6 24 -6 48 1 70 l10 34 6 -35 c3 -19 11 -89 17 -155 6 -66 16 -140 21
-164 6 -29 6 -54 -1 -75 -15 -48 -57 -259 -74 -376 -27 -184 -37 -330 -38
-545 -1 -219 4 -304 28 -493 25 -199 35 -253 74 -417 85 -349 203 -649 372
-943 26 -46 48 -86 48 -88 0 -13 185 -274 258 -364 346 -424 743 -721 1229
-920 56 -22 115 -45 130 -50 15 -4 73 -22 128 -40 125 -39 275 -72 430 -96
182 -29 640 -26 815 4 458 79 759 187 1135 406 122 71 374 257 505 371 109 96
335 338 438 470 54 70 92 124 92 133 0 5 4 12 8 14 5 2 31 37 59 78 112 167
231 383 302 550 18 41 39 90 47 108 9 18 24 59 35 90 11 31 28 80 39 107 41
107 71 218 61 228 -6 6 -37 4 -88 -7 -43 -9 -100 -21 -128 -26 -101 -20 -296
-29 -410 -19 -259 24 -432 104 -604 279 -77 78 -133 159 -173 249 -16 36 -37
84 -47 106 -10 22 -29 64 -41 93 -13 29 -26 49 -31 45 -4 -4 -3 -66 1 -138 30
-470 39 -684 28 -691 -9 -6 -134 -19 -322 -34 -160 -13 -155 -16 -179 75 -20
79 -25 115 -42 345 -8 107 -19 251 -24 320 -8 98 -20 450 -21 598 0 12 -4 22
-10 22 -10 0 -36 -81 -76 -235 -12 -49 -32 -123 -44 -163 -11 -40 -38 -141
-60 -225 -22 -83 -49 -186 -60 -227 -35 -128 -81 -302 -95 -360 -37 -147 -38
-149 -66 -155 -18 -4 -29 -15 -33 -30 -8 -31 -25 -35 -165 -38 l-114 -2 -28
55 c-15 30 -49 102 -75 160 -91 202 -106 235 -130 285 -48 103 -114 247 -114
251 0 5 -69 161 -124 279 -23 50 -52 115 -65 145 -25 61 -48 96 -57 88 -5 -6
19 -209 51 -433 7 -55 19 -149 24 -210 6 -60 19 -186 29 -280 24 -241 19 -376
-15 -389 -9 -4 -52 -9 -97 -11 -87 -5 -198 -14 -260 -21 -45 -5 -68 10 -61 40
3 11 1 50 -5 86 -6 36 -15 108 -20 160 -5 52 -16 167 -24 254 -8 87 -13 162
-11 166 3 5 -1 25 -9 46 -12 35 -26 169 -32 294 -4 117 -16 237 -23 255 -13
30 -48 434 -43 490 1 16 -2 39 -7 50 -5 11 -15 74 -21 140 -7 66 -14 124 -17
128 -9 14 32 110 52 122 11 7 120 30 242 51 l221 37 34 -27 c52 -44 127 -135
134 -164 4 -15 35 -91 70 -169 35 -78 75 -170 90 -205 14 -35 45 -106 69 -158
24 -52 59 -132 79 -177 79 -181 110 -251 137 -311 16 -35 29 -65 29 -67 0 -16
53 -115 61 -115 13 0 25 34 50 143 12 51 30 126 41 167 11 41 23 93 28 115 5
22 45 184 89 360 101 405 157 640 164 687 6 41 -7 66 -40 72 -12 3 -32 10 -44
16 -26 15 -68 21 -92 13 -16 -4 -17 -11 -12 -45 4 -22 3 -38 -1 -36 -56 27
-87 37 -96 32 -7 -5 -9 -41 -5 -110 2 -57 1 -104 -3 -104 -5 0 -32 45 -61 100
-29 55 -60 102 -68 105 -7 3 -22 -2 -32 -11 -22 -20 -35 -13 -49 31 l-11 34
-37 -19 c-43 -24 -52 -25 -52 -6 0 7 -6 19 -14 25 -12 10 -33 3 -105 -35 -50
-26 -94 -44 -98 -40 -4 4 5 29 20 56 36 64 35 80 -5 81 -18 1 -37 1 -42 0 -5
-1 -16 1 -25 4 -13 5 -14 7 -1 15 11 7 7 10 -16 10 -51 0 -94 -12 -94 -26 0
-22 78 -131 110 -154 16 -12 30 -28 30 -35 0 -20 50 -108 66 -118 8 -4 14 -12
14 -17 0 -10 29 -54 74 -113 16 -21 26 -40 23 -43 -8 -9 -30 15 -45 49 -6 15
-17 25 -22 22 -6 -4 -17 9 -24 27 -8 19 -31 51 -50 73 -20 21 -36 43 -36 48 0
5 -7 6 -16 3 -21 -8 -62 21 -80 56 -18 35 -137 158 -153 158 -6 0 -11 -3 -11
-8 0 -4 43 -61 95 -126 104 -131 159 -210 153 -225 -1 -6 -51 36 -110 92 -147
140 -185 170 -260 211 -81 45 -108 45 -293 7 -251 -53 -289 -62 -340 -87 -37
-17 -67 -24 -112 -24 -34 0 -65 -4 -68 -10 -3 -5 -15 -10 -25 -10 -10 0 -46
-16 -79 -36 -60 -36 -62 -36 -127 -28 l-66 9 54 32 c43 26 65 32 118 34 79 3
182 24 213 43 12 8 52 17 89 21 37 3 70 11 73 16 4 5 14 9 24 9 19 0 303 80
354 99 29 11 36 38 15 59 -8 8 -8 12 -1 12 6 0 16 -7 23 -15 15 -18 40 -20 40
-3 0 34 -300 295 -455 396 -44 29 -85 55 -90 60 -6 4 -29 17 -51 30 -23 12
-66 35 -95 52 -297 162 -578 224 -789 171 -123 -31 -204 -74 -276 -146 -156
-159 -221 -423 -184 -750 16 -132 26 -178 100 -430 46 -154 198 -480 309 -660
141 -229 261 -390 452 -604 106 -119 218 -227 361 -347 35 -29 147 -116 178
-137 80 -55 170 -113 214 -137 28 -15 67 -36 86 -47 19 -11 75 -36 123 -57
259 -111 523 -139 692 -75 49 18 89 24 210 28 156 7 155 6 278 69 17 9 4 -38
-27 -92 -65 -112 -186 -215 -313 -265 -384 -151 -977 63 -1548 560 -113 98
-351 338 -411 414 -21 27 -49 61 -62 75 -67 72 -305 408 -370 520 -11 19 -46
80 -77 135 -238 414 -395 924 -395 1283 0 458 187 752 530 831 213 49 508 -5
800 -146 247 -119 531 -321 763 -542 34 -33 80 -74 102 -91 34 -27 41 -29 43
-15 2 9 -10 33 -26 54 -17 21 -29 49 -29 64 0 16 -10 37 -22 49 -12 11 -20 22
-17 25 3 3 31 -18 62 -48 31 -30 65 -61 74 -70 36 -34 108 -69 141 -69 18 0
51 7 74 15 22 8 59 19 82 25 24 6 55 21 70 34 27 24 28 24 9 45 -11 12 -25 21
-33 21 -27 0 -12 18 21 24 18 4 41 15 51 26 16 18 15 21 -26 70 -50 59 -43 73
31 63 79 -11 85 -10 88 18 3 25 6 26 36 19 19 -4 38 -10 43 -13 5 -3 15 -2 23
3 11 7 16 34 18 94 l4 84 52 -62 c46 -53 57 -61 92 -64 21 -1 49 -8 61 -14 17
-8 29 -8 48 0 32 15 67 15 97 1 19 -8 37 -4 98 21 41 18 86 34 99 37 21 5 23
3 16 -13 -11 -27 -24 -98 -19 -104 3 -2 44 -7 92 -10 48 -4 102 -13 121 -20
48 -18 215 -6 272 20 45 20 109 27 109 12 0 -23 -43 -45 -92 -47 -55 -3 -82
-10 -158 -41 -55 -22 -85 -48 -100 -84 -7 -16 -24 -33 -41 -39 -16 -6 -42 -16
-58 -22 -43 -16 -91 -73 -91 -108 0 -20 -6 -30 -19 -34 -23 -6 -31 -50 -17
-86 10 -23 13 -23 96 -17 47 3 188 23 314 43 l229 37 37 -43 c43 -48 41 -39
60 -356 16 -271 21 -311 35 -291 7 9 16 37 20 61 9 62 56 198 95 275 99 196
266 363 465 463 64 32 218 82 320 104 89 19 367 24 468 9 49 -7 57 -6 57 8 0
22 -54 142 -119 261 -29 55 -65 121 -79 148 -14 26 -29 47 -33 47 -5 0 -9 5
-9 10 0 6 -8 23 -18 38 -11 15 -43 63 -72 107 -30 44 -56 82 -60 85 -3 3 -39
48 -80 100 -40 52 -90 113 -109 134 -58 64 -250 254 -290 285 -20 16 -67 55
-106 86 -38 32 -90 71 -115 88 -25 17 -49 35 -55 39 -13 11 -134 89 -160 103
-371 207 -601 292 -1000 368 -147 28 -264 37 -481 37 -249 0 -394 -15 -634
-67 -152 -32 -381 -99 -422 -123 -10 -6 -22 -10 -28 -10 -21 0 -293 -131 -395
-189 -38 -23 -79 -45 -90 -50 -38 -17 -307 -203 -335 -231 -3 -3 -27 -23 -55
-45 -109 -87 -303 -272 -355 -341 -31 -39 -49 -54 -67 -54 -15 0 -23 -5 -20
-12 2 -7 13 -12 23 -11 82 11 99 10 111 -6 9 -12 12 -12 13 -2 0 10 15 12 73
6 39 -3 86 -9 102 -12 27 -5 28 -7 10 -14 -19 -7 -18 -8 4 -8 18 -1 21 -4 12
-14 -6 -8 -8 -17 -4 -20 3 -4 -39 -7 -95 -7 -64 0 -102 4 -102 10 0 7 -6 7
-19 0 -11 -5 -30 -10 -42 -10 -17 0 -20 5 -17 25 5 22 2 25 -24 25 -26 0 -58
-19 -58 -34 0 -2 15 -6 33 -8 l32 -4 -29 -8 c-20 -6 -31 -5 -37 5 -8 11 -13
10 -28 -9 -13 -14 -38 -26 -73 -32 -31 -6 -59 -18 -68 -30 -15 -19 -70 -39
-70 -25 0 4 8 14 18 21 16 12 15 13 -5 14 -14 0 -23 6 -23 15 0 8 -9 15 -20
15 -22 0 -26 10 -9 27 7 7 3 9 -15 6 -30 -6 -37 -43 -8 -43 25 -1 55 -27 46
-41 -4 -7 1 -18 12 -27 18 -13 17 -14 -13 -9 -25 5 -31 3 -27 -7 5 -14 -95
-126 -112 -126 -16 0 -64 -24 -64 -32 0 -4 7 -11 16 -16 14 -8 13 -15 -11 -60
-15 -29 -33 -52 -41 -52 -8 0 -14 -5 -14 -11 0 -5 5 -7 10 -4 6 4 5 -8 -3 -27
-7 -18 -20 -33 -28 -33 -18 0 -31 -18 -18 -25 5 -4 3 -14 -6 -26 -13 -17 -14
-17 -9 -1 5 13 1 20 -15 24 -13 3 -21 13 -21 27 0 11 -5 28 -10 36 -7 11 -10
6 -10 -21 0 -24 5 -39 16 -43 8 -3 12 -11 9 -16 -11 -17 4 -60 20 -60 8 0 15
7 15 15 0 8 5 15 10 15 11 0 12 -15 4 -47 -4 -13 -10 -21 -13 -18 -4 2 -8 -1
-8 -8 -1 -7 -5 -55 -9 -107 -5 -52 -8 -126 -6 -165 1 -39 -3 -80 -10 -92 -9
-18 -8 -35 2 -77 7 -29 10 -59 6 -65 -4 -6 -1 -11 8 -11 8 0 16 3 19 8 2 4 2
1 0 -6 -1 -8 -3 -21 -3 -29 0 -11 -6 -14 -21 -10 -18 5 -20 2 -15 -19 5 -17 2
-24 -9 -24 -18 0 -20 -16 -3 -26 6 -4 16 0 23 9 22 30 27 18 21 -40 -4 -42 -3
-52 4 -35 5 12 14 22 20 22 7 0 8 -6 0 -19 -13 -24 -13 -88 -1 -96 6 -3 15 3
21 12 9 15 10 10 8 -24 -3 -34 0 -43 12 -43 11 0 23 -21 38 -62 28 -84 37
-132 23 -124 -8 5 -10 -19 -7 -81 5 -104 15 -163 27 -163 5 0 9 -6 9 -13 0 -8
9 -25 21 -38 11 -13 19 -35 18 -49 -2 -40 0 -50 12 -50 5 0 7 5 4 10 -3 6 -1
10 4 10 6 0 11 -8 11 -19 0 -32 24 -67 43 -63 9 2 17 10 17 19 0 13 2 13 16 2
10 -8 12 -15 5 -17 -6 -2 -11 -21 -11 -42 0 -21 -5 -40 -11 -42 -18 -6 -2 -78
16 -78 8 0 15 -7 15 -16 0 -8 4 -13 9 -9 5 3 7 22 4 42 l-6 37 24 -23 c19 -17
21 -24 10 -30 -9 -6 -10 -11 -2 -15 19 -12 13 -46 -9 -46 -11 0 -20 -4 -20
-10 0 -5 4 -10 9 -10 10 0 26 -26 55 -87 8 -18 19 -33 25 -33 5 0 15 -16 21
-36 7 -19 20 -40 31 -47 10 -6 19 -17 19 -24 0 -7 7 -13 15 -13 8 0 15 5 15
12 0 6 3 9 6 6 3 -3 0 -15 -7 -26 -11 -17 -10 -27 7 -57 10 -21 28 -44 39 -54
12 -9 26 -29 33 -46 7 -16 26 -46 43 -65 17 -19 47 -58 65 -87 19 -28 41 -54
49 -57 8 -3 15 -11 15 -18 0 -8 8 -21 18 -30 32 -32 59 -63 81 -95 27 -40 80
-93 92 -93 5 0 9 -5 9 -12 0 -6 23 -33 50 -60 28 -26 50 -50 50 -53 0 -9 40
-45 50 -45 6 0 10 -6 10 -13 0 -7 8 -20 18 -28 9 -9 26 -27 38 -40 11 -13 43
-42 69 -64 27 -22 79 -69 117 -105 38 -36 74 -67 80 -69 6 -2 18 -11 28 -20
36 -34 92 -81 97 -81 3 0 42 -28 87 -61 45 -34 91 -65 104 -70 12 -5 22 -13
22 -19 0 -5 5 -10 10 -10 6 0 35 -15 63 -34 132 -84 346 -190 442 -219 28 -9
75 -25 105 -36 136 -52 417 -72 585 -42 74 13 187 59 243 98 18 13 22 13 22 1
0 -8 -19 -23 -42 -34 -24 -11 -61 -30 -83 -42 -66 -36 -213 -62 -345 -62 -111
0 -352 35 -405 60 -11 5 -34 12 -52 15 -18 4 -42 14 -55 24 -13 10 -36 21 -53
24 -16 3 -82 31 -145 61 -119 58 -132 65 -240 131 -36 22 -77 47 -92 55 -29
17 -30 17 -207 149 -69 52 -137 105 -151 118 -14 13 -45 41 -70 61 -66 55
-409 399 -455 457 -21 28 -57 71 -80 96 -39 44 -95 117 -192 249 -26 36 -50
67 -53 70 -3 3 -20 28 -38 55 -17 28 -61 95 -96 150 -36 55 -76 120 -88 145
-13 25 -26 47 -30 50 -14 11 -202 395 -237 486 -11 28 -29 73 -41 100 -12 27
-28 65 -37 84 -8 19 -22 49 -30 67 -8 17 -13 35 -9 41 3 5 -7 58 -23 118 -32
123 -33 126 -63 284 -29 158 -28 480 3 634 11 55 20 121 19 145 -1 36 2 43 15
37 9 -3 13 -2 10 3 -3 5 9 40 27 77 23 48 55 90 112 148 91 91 103 101 166
135 25 14 47 28 50 31 8 11 170 69 225 80 27 6 52 15 55 19 13 22 119 138 149
165 19 17 48 46 65 65 17 19 46 48 64 65 19 17 49 45 68 63 66 61 132 113 143
113 6 0 11 4 11 9 0 4 17 20 38 34 20 14 46 32 57 41 93 73 137 104 185 129
30 16 62 35 70 41 8 7 38 23 65 37 28 13 54 30 58 37 4 6 12 12 18 12 5 0 39
14 74 30 35 17 69 30 75 30 5 0 10 4 10 9 0 9 88 41 113 41 8 0 20 7 27 15 7
8 19 15 27 15 8 0 37 11 65 25 27 14 59 25 71 25 12 0 34 6 48 14 15 7 66 23
115 35 49 12 112 28 139 36 28 8 61 16 75 18 24 4 24 4 -2 6 -16 0 -28 6 -28
11 0 13 -157 13 -162 0 -4 -11 -253 -19 -264 -8 -4 5 -2 19 4 33 11 25 13 25
137 25 77 0 125 4 125 10 0 6 47 10 120 10 73 0 120 -4 120 -10 0 -5 12 -10
28 -9 27 1 27 1 3 8 -42 12 -37 31 8 31 29 0 41 -4 41 -14 0 -10 13 -14 50
-13 27 1 45 4 40 5 -6 2 -10 12 -10 22 0 16 13 19 118 25 64 3 155 7 202 8 55
1 88 6 93 15 6 9 39 11 128 8 98 -3 121 -1 127 11 7 12 16 13 48 4 66 -18 92
-14 123 21 25 29 34 32 75 29 28 -1 46 2 46 8 0 7 55 11 168 11 167 0 212 5
212 21 0 5 -15 13 -32 18 -28 9 -25 9 22 6 l55 -5 -50 16 c-75 23 -224 52
-380 75 -106 15 -209 20 -427 23 -158 2 -289 1 -292 -1z m1071 -139 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-2127 -158 c0 -9 -7 -12 -21
-9 -16 4 -17 8 -7 14 19 13 28 11 28 -5z m2747 -12 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m-2617 -71 c0 -7 -7 -13 -15 -13 -10 0 -15 10 -15
33 1 30 1 31 15 13 8 -11 15 -26 15 -33z m2779 -4 c23 -8 31 -17 31 -34 0 -26
-15 -41 -25 -26 -3 6 -12 8 -20 4 -8 -3 -22 -2 -31 1 -15 6 -14 9 5 30 17 18
19 25 9 29 -25 10 -1 7 31 -4z m-102 -5 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m13 -34 c0 -6 -33 -10 -80 -10 -47 0 -80 4 -80 10 0 6 33
10 80 10 47 0 80 -4 80 -10z m305 -80 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10
0 6 4 10 9 10 6 0 13 -4 16 -10z m338 -248 c-3 -14 -12 -22 -21 -20 -25 5 -25
27 0 37 28 11 29 10 21 -17z m45 -14 c17 -13 20 -19 9 -15 -10 4 -25 7 -33 7
-8 0 -14 7 -14 15 0 20 7 19 38 -7z m-4208 -850 c31 -9 33 -12 15 -19 -35 -14
-64 -10 -75 10 -14 28 -13 34 8 26 9 -4 33 -12 52 -17z m97 -42 c-3 -8 1 -20
9 -26 13 -11 18 -7 16 11 -1 4 9 6 22 5 13 0 22 -4 19 -8 -2 -5 5 -8 16 -8 23
0 37 -16 19 -23 -7 -2 -10 -7 -6 -11 3 -4 15 -2 26 5 24 15 82 -5 82 -28 0
-10 6 -13 17 -8 11 4 48 -9 100 -36 81 -42 82 -42 57 -56 -27 -13 -49 -9 -39
8 4 5 -3 9 -14 9 -33 0 -50 11 -44 27 5 13 -1 15 -29 11 -24 -2 -34 0 -31 8 2
7 -13 14 -39 18 -53 9 -41 5 -118 34 -36 13 -77 26 -93 29 -15 3 -30 9 -33 14
-3 5 -20 9 -37 9 -24 0 -28 3 -17 10 8 5 31 10 50 10 26 0 31 -3 20 -10 -12
-8 -11 -10 8 -10 13 0 22 6 22 15 0 8 5 15 11 15 6 0 9 -6 6 -14z m427 -192
c8 -3 12 -12 10 -19 -4 -8 7 -20 25 -29 37 -20 61 -22 43 -4 -24 24 -11 27 23
7 19 -12 35 -25 35 -30 0 -5 7 -9 15 -9 8 0 15 -4 15 -10 0 -13 -6 -13 -31 0
-15 8 -19 7 -19 -5 0 -21 -16 -19 -32 4 -7 10 -28 22 -46 25 -17 4 -32 11 -32
16 0 5 -9 11 -19 15 -13 4 -17 12 -14 25 6 22 6 22 27 14z m209 -118 c3 -8 3
-17 0 -20 -7 -7 -33 13 -33 25 0 15 27 10 33 -5z m55 -33 c15 -10 34 -24 42
-30 8 -7 24 -20 35 -28 19 -15 18 -16 -15 -10 -31 4 -32 4 -11 -5 14 -6 31 -8
38 -5 8 2 26 -6 41 -20 15 -14 32 -25 39 -25 28 0 11 -25 -17 -26 -24 -1 -29
3 -27 19 1 11 -4 22 -10 24 -7 3 -13 -2 -13 -11 0 -24 -28 -19 -46 9 -9 14
-22 25 -29 25 -13 0 -27 24 -29 49 -1 19 -26 26 -26 8 0 -14 -3 -14 -30 -1
-34 17 -41 39 -10 29 11 -4 20 -2 20 4 0 16 17 13 48 -6z m-2000 -145 c-28
-81 -44 -144 -55 -220 -3 -21 -9 -38 -14 -38 -14 0 0 92 29 190 13 47 27 96
30 110 6 30 21 52 32 45 4 -2 -6 -42 -22 -87z m2218 -35 c-7 -7 -26 7 -26 19
0 6 6 6 15 -2 9 -7 13 -15 11 -17z m-28 -12 c17 -7 32 -17 32 -23 0 -14 -39 1
-62 24 -11 11 -17 19 -12 16 5 -2 24 -10 42 -17z m97 -37 c22 -20 55 -49 73
-65 18 -16 32 -35 30 -41 -2 -6 -25 9 -50 33 -79 76 -87 80 -89 52 -1 -12 -4
-10 -9 7 -19 61 -10 64 45 14z m135 -179 c7 -9 8 -15 2 -15 -5 0 -12 7 -16 15
-3 8 -4 15 -2 15 2 0 9 -7 16 -15z m-2496 -162 c-2 -37 -3 -75 -3 -82 -1 -10
-4 -11 -10 -5 -11 10 -2 166 8 159 4 -2 6 -34 5 -72z m223 -125 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1951 -315 c6 -10 13 -54 16 -98 7
-84 16 -172 40 -395 17 -158 30 -291 51 -525 9 -93 22 -224 30 -290 7 -66 19
-178 25 -250 6 -71 18 -191 27 -265 16 -138 13 -324 -4 -341 -6 -6 -19 -2 -34
9 -13 10 -42 26 -63 37 -22 10 -49 26 -60 37 -11 10 -25 17 -31 15 -5 -2 -30
10 -55 26 -25 17 -57 39 -72 49 -15 10 -30 18 -34 18 -4 0 -21 11 -38 24 -18
13 -40 25 -51 27 -17 4 -21 16 -27 79 -24 242 -53 456 -62 462 -12 8 -59 4
-254 -23 -180 -24 -211 -24 -218 -1 -3 9 -11 29 -19 45 -8 15 -12 34 -9 42 4
8 1 15 -6 15 -6 0 -8 -4 -5 -10 3 -5 2 -10 -3 -10 -15 0 -62 59 -62 78 0 10
-11 25 -25 32 -14 7 -39 35 -56 62 -17 26 -36 48 -43 48 -13 0 -47 49 -72 105
-9 22 -23 47 -29 55 -11 13 -56 95 -117 210 -10 19 -18 43 -18 54 0 11 -3 16
-7 12 -9 -8 -96 173 -125 258 -10 32 -17 61 -14 64 3 3 -4 10 -17 16 -25 13
-47 80 -31 95 13 13 336 66 355 59 11 -4 19 -42 33 -159 9 -85 26 -226 36
-314 11 -88 22 -190 26 -227 4 -39 13 -73 21 -80 15 -12 110 -2 358 36 50 8
122 17 160 21 39 4 76 12 83 17 6 6 12 31 12 57 0 46 -25 270 -34 313 -3 13
-13 101 -21 196 l-16 173 31 49 31 48 122 22 c67 13 138 26 157 30 64 14 109
11 118 -7z m-2014 -262 c1 -23 -1 -41 -4 -41 -13 0 -28 47 -23 74 8 39 24 18
27 -33z m482 -778 c-11 -11 -19 6 -11 24 8 17 8 17 12 0 3 -10 2 -21 -1 -24z
m79 -71 c0 -18 -20 -15 -23 4 -3 10 1 15 10 12 7 -3 13 -10 13 -16z m79 -146
c-6 -27 -18 -33 -29 -15 -7 10 24 53 32 45 2 -2 1 -15 -3 -30z m90 -131 c-4
-8 -11 -12 -16 -9 -6 4 -5 10 3 15 19 12 19 11 13 -6z m173 -241 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-252 -93 c11 -28 23 -51 27 -51 4
0 8 -7 8 -15 0 -8 7 -15 15 -15 10 0 15 -10 15 -32 0 -18 4 -28 8 -21 10 15
38 -5 43 -32 2 -11 14 -24 27 -30 12 -5 22 -14 22 -20 0 -5 16 -27 35 -47 19
-20 35 -40 35 -45 0 -4 18 -21 40 -37 27 -20 40 -38 40 -54 0 -13 4 -21 9 -18
4 3 17 -1 27 -9 10 -8 26 -15 35 -15 8 0 26 -11 39 -25 21 -23 22 -28 11 -65
-6 -22 -19 -44 -29 -49 -25 -13 -352 -35 -362 -25 -5 5 -12 43 -15 84 -4 41
-10 91 -15 110 -8 31 -25 187 -35 325 -2 22 -7 64 -11 94 -9 66 3 62 31 -8z
m2990 -638 c-10 -40 -17 -20 -9 23 5 24 9 32 12 21 2 -10 1 -30 -3 -44z m-42
-142 c-5 -3 -14 -17 -20 -31 -6 -14 -12 -19 -12 -12 -1 22 32 78 37 63 2 -7 0
-16 -5 -20z m-78 -131 c-21 -28 -40 -49 -42 -46 -6 6 5 24 41 70 44 53 44 35
1 -24z m-67 -89 c-7 -12 -16 -21 -21 -21 -4 0 0 11 9 25 20 30 31 26 12 -4z
m-82 -69 c-8 -9 -19 -13 -22 -9 -10 9 16 38 28 31 6 -4 4 -13 -6 -22z"/>
<path d="M4491 6274 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4298 6273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4151 6238 c0 -18 3 -26 6 -18 3 8 9 17 13 20 4 3 2 11 -6 18 -12 11
-14 8 -13 -20z"/>
<path d="M3340 6126 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M3228 6083 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3160 6065 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M3381 5864 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4201 5854 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3247 5846 c-4 -10 -1 -13 9 -9 7 3 14 9 14 14 0 14 -17 10 -23 -5z"/>
<path d="M3271 5814 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4562 5650 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M4560 5560 c0 -22 3 -40 8 -40 4 0 6 18 4 40 -2 22 -6 40 -8 40 -2 0
-4 -18 -4 -40z"/>
<path d="M3411 5554 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4220 5554 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M4231 5464 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3768 5333 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3673 5323 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M3665 5280 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4601 5198 c-1 -21 4 -38 9 -38 12 0 12 27 0 55 -7 16 -9 13 -9 -17z"/>
<path d="M4280 5050 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4280 4951 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M4370 4490 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4600 4381 c20 -11 37 -14 39 -9 5 14 -19 28 -49 28 -23 0 -22 -2 10
-19z"/>
<path d="M3233 4555 c-9 -25 4 -29 17 -6 7 15 7 21 0 21 -6 0 -13 -7 -17 -15z"/>
<path d="M3255 4470 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3271 4420 c0 -8 4 -22 9 -30 12 -18 12 -2 0 25 -6 13 -9 15 -9 5z"/>
<path d="M3236 4385 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
<path d="M3325 4170 c-3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 -10 10 -15 10
-5 0 -12 -4 -15 -10z"/>
<path d="M6593 9313 c4 -6 23 -14 43 -17 19 -4 43 -10 51 -13 10 -4 14 -2 10
4 -4 6 -23 14 -43 17 -19 4 -43 10 -51 13 -10 4 -14 2 -10 -4z"/>
<path d="M6761 9264 c6 -8 18 -14 25 -14 7 0 11 -6 8 -14 -3 -8 -1 -17 5 -21
7 -4 8 0 4 11 -5 14 -1 15 28 9 19 -3 43 -9 54 -12 46 -13 4 7 -63 30 -48 16
-68 20 -61 11z"/>
<path d="M6748 9233 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5378 9103 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5120 9076 c0 -9 7 -12 20 -9 11 3 17 9 14 14 -8 14 -34 10 -34 -5z"/>
<path d="M5218 9083 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5040 9060 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M3185 7881 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M3065 7859 c-28 -6 -26 -7 13 -8 42 -1 53 3 30 11 -7 2 -26 1 -43 -3z"/>
<path d="M3023 7825 c0 -8 4 -12 9 -9 4 3 8 9 8 15 0 5 -4 9 -8 9 -5 0 -9 -7
-9 -15z"/>
<path d="M3475 7809 c-16 -6 -14 -8 13 -8 33 -1 41 4 20 12 -7 2 -22 1 -33 -4z"/>
<path d="M2968 7753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2788 7723 c-10 -2 -18 -11 -18 -18 0 -7 -6 -15 -12 -18 -7 -2 -1 -4
14 -3 14 0 24 4 21 9 -2 4 3 10 11 13 9 3 16 10 16 15 0 9 -4 10 -32 2z"/>
<path d="M2830 7690 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2670 7602 c0 -4 -4 -24 -9 -44 -7 -30 -6 -38 5 -38 8 0 14 6 14 14
0 7 6 19 14 26 8 6 12 18 10 25 -6 15 -34 29 -34 17z"/>
<path d="M2705 7530 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2618 7478 c7 -7 12 -21 12 -32 0 -10 3 -17 6 -13 10 10 -5 57 -18
57 -9 0 -9 -3 0 -12z"/>
<path d="M2556 7364 c-4 -14 -5 -28 -3 -31 3 -2 8 8 11 23 4 14 5 28 3 31 -3
2 -8 -8 -11 -23z"/>
<path d="M2610 7370 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2642 7349 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
<path d="M2530 7258 c0 -18 5 -28 15 -28 19 0 19 11 0 36 -14 18 -14 18 -15
-8z"/>
<path d="M2510 7048 c0 -109 11 -129 15 -26 1 35 5 78 9 96 6 26 4 32 -8 32
-14 0 -16 -16 -16 -102z"/>
<path d="M2550 7137 c0 -19 16 -31 24 -18 3 5 -1 14 -9 21 -12 10 -15 10 -15
-3z"/>
<path d="M2575 7099 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M2556 7045 c-3 -9 -5 -33 -5 -53 2 -33 2 -32 9 13 8 53 6 66 -4 40z"/>
<path d="M2580 7002 c0 -23 5 -42 10 -42 7 0 8 14 4 38 -9 51 -14 53 -14 4z"/>
<path d="M7970 6930 c-12 -8 -11 -10 8 -10 12 0 22 5 22 10 0 13 -11 13 -30 0z"/>
<path d="M8058 6933 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8408 6933 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8138 6883 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2551 6853 c-14 -35 -8 -74 10 -56 6 6 9 28 7 49 l-3 39 -14 -32z"/>
<path d="M2590 6840 c0 -22 5 -40 10 -40 6 0 10 18 10 40 0 22 -4 40 -10 40
-5 0 -10 -18 -10 -40z"/>
<path d="M8641 6854 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2512 6820 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M7625 6810 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M7555 6769 c-11 -17 -2 -18 25 -4 24 14 24 14 3 15 -12 0 -25 -5 -28
-11z"/>
<path d="M2515 6737 c-3 -12 -2 -34 4 -47 9 -22 10 -19 10 23 1 51 -5 61 -14
24z"/>
<path d="M7475 6699 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z"/>
<path d="M6840 6696 c0 -2 9 -6 20 -9 11 -3 20 -1 20 4 0 5 -9 9 -20 9 -11 0
-20 -2 -20 -4z"/>
<path d="M8820 6675 c0 -8 7 -15 15 -15 9 0 12 6 9 15 -4 8 -10 15 -15 15 -5
0 -9 -7 -9 -15z"/>
<path d="M7406 6661 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M2531 6624 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2560 6626 c0 -8 7 -16 15 -20 10 -4 15 1 15 14 0 11 -7 20 -15 20
-8 0 -15 -6 -15 -14z"/>
<path d="M8045 6610 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10 -8
0 -18 -4 -21 -10z"/>
<path d="M8280 6570 c0 -5 10 -10 23 -10 18 0 19 2 7 10 -19 13 -30 13 -30 0z"/>
<path d="M2546 6528 c10 -56 13 -63 26 -50 9 9 9 15 0 24 -7 7 -12 22 -12 35
0 13 -4 23 -10 23 -5 0 -7 -14 -4 -32z"/>
<path d="M7311 6544 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7878 6553 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7967 6515 c-187 -47 -300 -158 -371 -367 -34 -99 -52 -332 -37 -467
5 -50 7 -106 4 -126 -3 -19 0 -54 6 -77 6 -24 13 -56 16 -73 6 -49 40 -167 59
-207 17 -35 93 -101 146 -126 46 -21 114 -32 215 -32 120 0 232 22 285 55 22
14 44 25 49 25 6 0 26 14 46 31 l35 31 0 109 c0 91 -3 111 -16 116 -9 3 -83
-2 -165 -11 -83 -9 -156 -13 -164 -9 -7 5 -37 39 -66 78 l-52 70 -10 168 c-8
120 -8 170 0 178 15 15 74 26 308 54 330 41 407 53 418 64 13 13 -9 182 -26
194 -7 4 -37 5 -68 2 l-57 -6 -69 60 c-54 46 -72 69 -82 103 -21 69 -59 136
-88 155 -37 24 -232 29 -316 8z"/>
<path d="M8895 6490 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6262 6435 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M7694 6439 c-19 -22 -19 -22 3 -10 24 12 39 31 25 31 -5 0 -17 -10
-28 -21z"/>
<path d="M8465 6440 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2580 6388 c0 -25 11 -49 21 -42 5 3 9 0 9 -6 0 -6 5 -8 10 -5 15 9
12 47 -2 42 -7 -2 -18 3 -25 12 -11 15 -12 14 -13 -1z"/>
<path d="M8508 6389 c-6 -19 3 -59 13 -59 11 0 11 41 0 59 -7 11 -9 11 -13 0z"/>
<path d="M8960 6390 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M7630 6360 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5190 6337 c0 -7 7 -22 15 -33 13 -17 14 -16 4 14 -11 33 -19 41 -19
19z"/>
<path d="M4660 6326 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M6211 6254 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7140 6236 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M6198 6153 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6891 6144 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4696 6131 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M5281 6124 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7521 6114 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6171 6092 c-7 -13 -11 -28 -8 -35 2 -7 9 -2 16 11 7 13 11 28 8 35
-2 7 -9 2 -16 -11z"/>
<path d="M2680 6085 c0 -11 9 -42 20 -70 18 -48 19 -48 20 -17 0 17 -4 32 -10
32 -5 0 -10 10 -10 23 0 12 -5 29 -10 37 -8 12 -10 12 -10 -5z"/>
<path d="M5314 6047 c3 -10 6 -25 6 -33 0 -8 5 -14 11 -14 12 0 2 40 -14 55
-7 7 -8 4 -3 -8z"/>
<path d="M4695 6020 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M6142 5955 c-9 -20 -9 -23 3 -18 8 3 15 14 15 24 0 25 -5 24 -18 -6z"/>
<path d="M6420 5963 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22
-5 0 -10 -3 -10 -7z"/>
<path d="M5361 5934 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4718 5893 c7 -65 12 -67 12 -5 0 29 -4 52 -9 52 -5 0 -6 -21 -3 -47z"/>
<path d="M2740 5906 c0 -4 12 -33 27 -64 19 -39 29 -51 31 -39 2 10 -3 26 -12
36 -9 10 -16 25 -16 33 0 9 -7 21 -15 28 -8 7 -15 9 -15 6z"/>
<path d="M9018 5843 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8698 5813 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6401 5794 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6080 5770 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5388 5763 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5481 5679 c-1 -8 10 -30 24 -49 14 -19 25 -29 25 -23 0 7 -7 18 -15
27 -8 8 -19 25 -24 38 -6 14 -10 17 -10 7z"/>
<path d="M8100 5666 c0 -8 5 -18 10 -21 6 -3 10 3 10 14 0 12 -4 21 -10 21 -5
0 -10 -6 -10 -14z"/>
<path d="M4753 5635 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M2879 5655 c-3 -6 -3 -21 1 -35 3 -14 8 -20 11 -15 3 6 3 21 -1 35
-3 14 -8 20 -11 15z"/>
<path d="M5057 5540 c-3 -11 -1 -20 4 -20 5 0 9 9 9 20 0 11 -2 20 -4 20 -2 0
-6 -9 -9 -20z"/>
<path d="M6027 5528 c-4 -12 -4 -24 -2 -27 3 -2 5 1 5 7 0 7 7 12 16 12 9 0
14 5 12 12 -7 21 -25 19 -31 -4z"/>
<path d="M5540 5530 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M5042 5465 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M5082 5440 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M5585 5450 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6271 5374 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6962 5305 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M6270 5309 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6251 5234 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5952 5215 c-14 -31 -15 -45 -3 -45 10 0 26 58 19 66 -3 2 -10 -7
-16 -21z"/>
<path d="M5690 5186 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"/>
<path d="M7140 5178 c0 -15 5 -30 10 -33 13 -8 13 25 0 45 -8 12 -10 9 -10
-12z"/>
<path d="M8625 5190 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6972 5150 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M5420 5123 c1 -13 29 -59 30 -48 0 21 -13 55 -21 55 -5 0 -9 -3 -9
-7z"/>
<path d="M7166 5087 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M5913 5035 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M8587 5034 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23
-2 6 -7 8 -11 4z"/>
<path d="M9042 5020 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5485 5011 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M4792 4970 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M7695 4990 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M7230 4975 c0 -8 4 -15 9 -15 5 0 17 -3 26 -7 16 -5 15 -4 -1 15 -22
26 -34 28 -34 7z"/>
<path d="M5780 4970 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M6172 4920 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5800 4921 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M5876 4913 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M7786 4922 c16 -14 64 -32 64 -24 0 4 -12 13 -27 20 -31 14 -52 16
-37 4z"/>
<path d="M9041 4904 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5551 4844 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7300 4851 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M6592 4815 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M6962 4820 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4861 4814 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5570 4800 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M4820 4784 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M7490 4676 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M7650 4596 c14 -8 27 -12 29 -10 6 6 -1 11 -29 18 l-25 7 25 -15z"/>
<path d="M8457 4589 c8 -8 16 -8 30 0 16 9 14 10 -11 11 -22 0 -27 -3 -19 -11z"/>
<path d="M7808 4553 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M7980 4540 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M6051 4494 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6960 4449 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5970 4370 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M5197 4331 c-3 -13 -1 -18 9 -14 7 2 10 11 7 19 -7 19 -10 18 -16 -5z"/>
<path d="M4870 4287 c0 -9 11 -12 38 -8 34 4 35 5 12 12 -39 11 -50 10 -50 -4z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
